export default {
  name: 'hamburger',

  props: ['navVisible'],

  watch: {
    // Manually trigger the animations when the data changes.
    navVisible() {
      this.$refs['animate-top-x1'].beginElement()
      this.$refs['animate-top-x2'].beginElement()
      this.$refs['animate-top-y1'].beginElement()
      this.$refs['animate-top-y2'].beginElement()
      this.$refs['animate-bottom-x1'].beginElement()
      this.$refs['animate-bottom-x2'].beginElement()
      this.$refs['animate-bottom-y1'].beginElement()
      this.$refs['animate-bottom-y2'].beginElement()
    },
  },

  methods: {
    getPosition(name, xy) {
      return {
        to: this.navVisible
          ? this.position[name].open[xy]
          : this.position[name].closed[xy],
        from: this.navVisible
          ? this.position[name].closed[xy]
          : this.position[name].open[xy],
      }
    },
  },

  computed: {
    top() {
      return this.navVisible ? this.position.top.open : this.position.top.closed
    },

    middleClasses() {
      let css = [`ease-${this.animation.duration}`]
      if (this.navVisible) css.push('o-0')
      return css
    },

    bottom() {
      return this.navVisible
        ? this.position.bottom.open
        : this.position.bottom.closed
    },

    animate() {
      return {
        top: {
          x1: this.getPosition('top', 'x1'),
          x2: this.getPosition('top', 'x2'),
          y1: this.getPosition('top', 'y1'),
          y2: this.getPosition('top', 'y2'),
        },
        bottom: {
          x1: this.getPosition('bottom', 'x1'),
          x2: this.getPosition('bottom', 'x2'),
          y1: this.getPosition('bottom', 'y1'),
          y2: this.getPosition('bottom', 'y2'),
        },
      }
    },
  },

  data() {
    return {
      animation: {
        duration: '150ms',
      },
      position: {
        top: {
          open: { x1: 3, x2: 21, y1: 3, y2: 21 },
          closed: { x1: 1, x2: 23, y1: 5, y2: 5 },
        },
        middle: { x1: 1, x2: 15, y1: 12, y2: 12 },
        bottom: {
          open: { x1: 3, x2: 21, y1: 21, y2: 3 },
          closed: { x1: 1, x2: 19, y1: 19, y2: 19 },
        },
      },
    }
  },
}
