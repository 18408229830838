import debounce from 'lodash/debounce'

export const scroll = {
  methods: {
    onScroll() {
      let scrollY = window.scrollY
      this.window.scrolling = scrollY > 0
      this.window.scrollingDown = scrollY > this.window.scrollY
      this.window.scrollingUp = scrollY > 0 && scrollY < this.window.scrollY
      this.window.scrollY = scrollY
    },
  },

  created() {
    this.debouncedScroll = debounce(this.onScroll, 15)
    window.addEventListener('scroll', this.debouncedScroll)
  },

  destroyed() {
    window.removeEventListener('scroll', this.debouncedScroll)
  },

  data() {
    return {
      window: {
        scrollY: 0,
        scrolling: false,
        scrollingUp: false,
        scrollingDown: false,
      },
    }
  },
}
