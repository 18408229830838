import Vue from 'vue'
import Timeago from 'vue-timeago'
import App from './layout/app.vue'
import './assets/styles/main.sass'
import router from './router'

Vue.use(Timeago, {
  locale: 'en',
  converter(date, locale, { includeSeconds, addSuffix = true }) {
    const distanceInWordsStrict = require('date-fns/distance_in_words_strict')
    return distanceInWordsStrict(Date.now(), date, {
      locale,
      addSuffix,
      includeSeconds,
    })
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
