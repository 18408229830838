import css from './404.css.yml'
import { analytics } from '@/mixins/analytics'

export default {
  name: 'page-not-found',

  mixins: [analytics],

  data() {
    return {
      css: css,
    }
  },
}
