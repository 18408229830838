export const analytics = {
  methods: {
    identifyUser(name, email) {
      if (window.analytics) {
        window.analytics.identify({
          name: name,
          email: email,
        })
      }
    },
  },

  mounted() {
    if (window.analytics) window.analytics.page(this.$route.name)
  },
}
