import css from './app-header.css.yml'
import { scroll } from '@/mixins/scroll'
import Icon from '@/components/icon/icon.vue'
import Hamburger from '@/components/hamburger/hamburger.vue'

export default {
  name: 'app-header',

  components: { Icon, Hamburger },

  mixins: [scroll],

  methods: {
    linkClass(isExactActive) {
      let classes = [this.css.nav.link]
      if (isExactActive) classes.push(this.css.nav.link__active)
      if (!isExactActive) classes.push(this.css.nav.link__inactive)
      return classes
    },

    itemClass(index) {
      let classes = [this.css.nav.item]
      if (index <= 2) classes.push(this.css.nav.item__left)
      if (index >= 3) classes.push(this.css.nav.item__right)
      return classes
    },

    toggle() {
      this.navVisible = !this.navVisible
    },

    close() {
      this.navVisible = false
    },
  },

  computed: {
    headerClasses() {
      let classes = [this.css.header.base]
      if (process.env.NODE_ENV != 'test') {
        if (this.window.scrolling) classes.push(this.css.header.__scrolling)
        if (this.window.scrollingUp)
          classes.push(this.css.header.__scrolling_up)
        if (this.window.scrollingDown)
          classes.push(this.css.header.__scrolling_down)
      }
      classes.push(
        this.navVisible ? this.css.header.__active : this.css.header.__inactive
      )
      return classes
    },

    navClasses() {
      let classes = [this.css.nav.base]
      classes.push(
        this.navVisible ? this.css.nav.__active : this.css.nav.__inactive
      )
      return classes
    },
  },

  data() {
    return {
      css: css,
      navVisible: false,
      links: [
        { name: 'Portfolio', path: '/' },
        { name: 'Services', path: '/services' },
        { name: 'Approach', path: '/approach' },
        { name: 'Contact', path: '/contact' },
        { name: 'About', path: '/about' },
        { name: 'Blog', path: '/blog' },
      ],
    }
  },
}
