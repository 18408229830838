import css from './index.css.yml'
import examples from './examples.yml'
import { analytics } from '@/mixins/analytics'

export default {
  name: 'portfolio',

  mixins: [analytics],

  data() {
    return {
      css: css,
      examples: examples,
    }
  },
}
