import Vue from 'vue'
import Router from 'vue-router'
import Portfolio from '@/views/portfolio/index.vue'
import PageNotFound from '@/views/404/404.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Portfolio',
      component: Portfolio,
    },
    {
      path: '/services',
      name: 'Services',
      component: () =>
        import(
          // webpackChunkName: "services"
          // webpackPrefetch: true
          '@/views/services/index.vue'
        ),
    },
    {
      path: '/approach',
      name: 'Approach',
      component: () =>
        import(
          // webpackChunkName: "approach"
          // webpackPrefetch: true
          '@/views/approach/index.vue'
        ),
    },
    {
      path: '/about',
      name: 'About',
      component: () =>
        import(
          // webpackChunkName: "about"
          // webpackPrefetch: true
          '@/views/about/index.vue'
        ),
    },
    {
      path: '/blog/:slug',
      name: 'Blog Post',
      component: () =>
        import(
          // webpackChunkName: "blog"
          // webpackPrefetch: true
          '@/views/blog/show.vue'
        ),
    },
    {
      path: '/blog',
      name: 'Blog',
      component: () =>
        import(
          // webpackChunkName: "blog"
          // webpackPrefetch: true
          '@/views/blog/index.vue'
        ),
    },
    {
      path: '/contact',
      name: 'Contact',
      component: () =>
        import(
          // webpackChunkName: "contact"
          // webpackPrefetch: true
          '@/views/contact/contact-form.vue'
        ),
    },
    {
      path: '/*',
      name: '404',
      component: PageNotFound,
    },
  ],
})
