import css from './app-footer.css.yml'
import links from './links.yml'
import Icon from '@/components/icon/icon.vue'

export default {
  name: 'app-footer',

  components: { Icon },

  data() {
    return {
      css: css,
      links: links,
    }
  },
}
