import css from './app.css.yml'
import AppHeader from '@/layout/header/app-header.vue'
import AppFooter from '@/layout/footer/app-footer.vue'

export default {
  name: 'app',

  components: { AppHeader, AppFooter },

  data() {
    return {
      css: css,
    }
  },
}
