import css from './icon.css.yml'

export default {
  name: 'icon',

  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    size: {
      type: Number,
      default: 16,
      required: false,
    },
  },

  computed: {
    icon() {
      return () =>
        import(
          // webpackChunkName: "icons"
          // webpackMode: "lazy-once"
          `@/assets/icon/x${this.size}/${this.name}.svg`
        )
    },

    titleId() {
      return `title-${this.name}`
    },
  },

  data() {
    return {
      css: css,
    }
  },
}
